/* Styles for IndexPage */

/* slide block */
.hero {
  position: relative;
  overflow: hidden;
}

.hero img {
  width: 100%;
  /* height: auto;  */
  /* display: block; */
  height: 70vh;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}


/* functions */
.features {
  margin-top: 20px;
}

.features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
}

.features-list li {
  text-align: center;
}

.features-list li > span{
  font-size: 50px;
  line-height: 1;
}
.features-list li > h4{
  font-size: 1.2em;
  margin: 1em 0;
}
.features-list li > p{
  font-size: 1em;
}



@media (max-width: 768px) {
  .features-list {
    grid-template-columns: 1fr;
  }
}