.logo-block{
    display: flex;
    grid-gap: 0.5em;
    align-items: center;
}

img.logo {
    width: 75px;
    height: auto;
}

.logo-text {
    width: 100px;
    display: flex;
    font-family: monospace;
    font-size: 2em;
    line-height: 1.1;
}

@media (max-width: 768px) {
    .logo-block{
        align-items: center;
        justify-content: center;
    }
}
