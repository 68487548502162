/* Styles for VersionsPage */

.version-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
}

.version-card .date {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.version-card .version {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}