.contact-page {
  padding-bottom: 2em;
  min-height: 50vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  grid-gap: 15px;
}

.contact-form .form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.contact-form input, textarea {
  /* width: 100%; */
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.contact-form button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-size: 14px;
  color: #ccc;
}

@media (max-width: 768px) {
  .contact-form{
    max-width: -webkit-fill-available;
  }
  .contact-page{
    min-height: 0;
  }
}
