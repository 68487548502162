/* Example CSS for HeroBanner */
.hero-banner {
    background: url('../../assets/img/hero-background.png') center/cover;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    /* background-size: contain; */
    position: relative;
}

.hero-content {
    max-width: 100%;
}

.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.install-button {
    background-color: #4caf50;
    color: #fff;
    font-size: 1.3em;
    padding: 0.8em 2em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 1em 0;
    border-radius: 10px;
}

.install-button:hover {
    background-color: #45a049;
}

.install-button:disabled{
    cursor: not-allowed;
    background-color: #eee;
    color: #968e8e;
    border: 1px solid #000;
}

@media (max-width: 768px) {
    .hero-banner {
        background-size: contain;
    }
    .hero-content h1 {
        font-size: 1.7em;
    }
}