/* Common styles for all pages */

body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1em;
    box-sizing: border-box;
}

h1, h2, h3 {
    font-weight: bold;
}

.row {
    display: flex;
    align-items: center;
}

.row.justify-c-sb{
    justify-content: space-between;
}
.row.justify-c-c{
    justify-content: center;
}

.text-center {
    text-align: center;
}

.video-box {
    max-height: 80vh;
}

.video-box {
    height: 100%;
    width: 100%;
    display: flex;
}

/* section */

section {
    padding-bottom: 2em;
    border-bottom: 1px solid #161616;
}

section h3 {
    font-weight: 600;
    font-size: 1.6em;
    text-transform: uppercase;
    margin: 1em 0 1.5em;
    position: relative;
}

section h3::after {
    content: '';
    display: block;
    background: #eee;
    width: 10%;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: -40%;
    transform: translateX(-59%);
}

section p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

section a {
    color: #007bff;
    text-decoration: none;
    border: 1px solid #ccc;
    padding: 5px 10px;
}

section a:hover {
    background-color: #eee;
    text-decoration: underline;
}

.page{
    padding: 1em 0;
}