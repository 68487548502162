header {
    /* display: flex;
    justify-content: space-between; /* Distribute elements horizontally with space between them */
    /* align-items: center;
    padding: 20px;  */
    background-color: #f8f9fa;
    padding: 1em 0;
    padding-top: 0;
}

header ul {
    list-style: none;
    /* Remove default list-style bullets */
    display: flex;
    margin-left: 3em;
    /* Adjust the margin-left to align the menu with the logo */
    grid-gap: 1em;
    padding: 0;
}

header ul li {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

header ul li:hover {
    background-color: #eee;
    /* Add hover effect to menu items */
}

header ul li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 0.5em 1em;
    border-radius: 5px;
}

header ul li a.active {
    background: #eee;
    outline: 1px solid blue;
}

.download-button{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.notify-block{
    background: rgb(0, 0, 0);
}

.notify-block .notify-message-block{
    padding: 1em;
    max-width: 50%;
    margin: 0 auto;
    color: #fff;
}

@media (max-width: 768px) {
    .download-button{
        display: none;
    }
    
    .row{
        flex-direction: column;
    }

    header ul{
        margin-left: 0;
        flex-direction: column;
        grid-gap: 0.3em;
    }
}