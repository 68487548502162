footer {
    background: #f8f9fa;
    padding: 1em 0;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1em;
}

footer p {
    font-size: 1em;
    margin: 0;
    margin-bottom: 1em;
}

footer p.copywriting {
    margin-bottom: 0;
    text-align: center;
    font-size: 80%;
}

@media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  }