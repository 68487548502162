/* base.css */

/* General styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    width: 100%;
    margin: 0 auto;
}

/* Responsive Grid */
/* Extra small devices (phones) */
.col {
    display: flex;
    flex-direction: column;
}

.col-1,
.col-xs-1 {
    width: 8.33333%;
    /* 1/12 */
}

/* Small devices (tablets) */
@media (min-width: 576px) {
    .col-2,
    .col-xs-2,
    .col-sm-1 {
        width: 16.66667%;
        /* 2/12 */
    }

    .col-3,
    .col-xs-3,
    .col-sm-2 {
        width: 25%;
        /* 3/12 */
    }

    .col-4,
    .col-xs-4,
    .col-sm-3 {
        width: 33.33333%;
        /* 4/12 */
    }

    .col-5,
    .col-xs-5,
    .col-sm-4 {
        width: 41.66667%;
        /* 5/12 */
    }

    .col-6,
    .col-xs-6,
    .col-sm-5 {
        width: 50%;
        /* 6/12 */
    }

    .col-7,
    .col-xs-7,
    .col-sm-6 {
        width: 58.33333%;
        /* 7/12 */
    }

    .col-8,
    .col-xs-8,
    .col-sm-7 {
        width: 66.66667%;
        /* 8/12 */
    }

    .col-9,
    .col-xs-9,
    .col-sm-8 {
        width: 75%;
        /* 9/12 */
    }

    .col-10,
    .col-xs-10,
    .col-sm-9 {
        width: 83.33333%;
        /* 10/12 */
    }

    .col-11,
    .col-xs-11,
    .col-sm-10 {
        width: 91.66667%;
        /* 11/12 */
    }

    .col-12,
    .col-xs-12,
    .col-sm-11 {
        width: 100%;
        /* 12/12 */
    }
}

/* Medium devices (desktops) */
@media (min-width: 768px) {

    .col-2,
    .col-xs-2,
    .col-sm-1,
    .col-md-1 {
        width: 16.66667%;
        /* 2/12 */
    }

    .col-3,
    .col-xs-3,
    .col-sm-2,
    .col-md-2 {
        width: 25%;
        /* 3/12 */
    }

    .col-4,
    .col-xs-4,
    .col-sm-3,
    .col-md-3 {
        width: 33.33333%;
        /* 4/12 */
    }

    .col-5,
    .col-xs-5,
    .col-sm-4,
    .col-md-4 {
        width: 41.66667%;
        /* 5/12 */
    }

    .col-6,
    .col-xs-6,
    .col-sm-5,
    .col-md-5 {
        width: 50%;
        /* 6/12 */
    }

    .col-7,
    .col-xs-7,
    .col-sm-6,
    .col-md-6 {
        width: 58.33333%;
        /* 7/12 */
    }

    .col-8,
    .col-xs-8,
    .col-sm-7,
    .col-md-7 {
        width: 66.66667%;
        /* 8/12 */
    }

    .col-9,
    .col-xs-9,
    .col-sm-8,
    .col-md-8 {
        width: 75%;
        /* 9/12 */
    }

    .col-10,
    .col-xs-10,
    .col-sm-9,
    .col-md-9 {
        width: 83.33333%;
        /* 10/12 */
    }

    .col-11,
    .col-xs-11,
    .col-sm-10,
    .col-md-10 {
        width: 91.66667%;
        /* 11/12 */
    }

    .col-12,
    .col-xs-12,
    .col-sm-11,
    .col-md-11 {
        width: 100%;
        /* 12/12 */
    }
}

/* Large devices (large desktops) */
@media (min-width: 992px) {

    .col-2,
    .col-xs-2,
    .col-sm-1,
    .col-md-1,
    .col-lg-1 {
        width: 16.66667%;
        /* 2/12 */
    }

    .col-3,
    .col-xs-3,
    .col-sm-2,
    .col-md-2,
    .col-lg-2 {
        width: 25%;
        /* 3/12 */
    }

    .col-4,
    .col-xs-4,
    .col-sm-3,
    .col-md-3,
    .col-lg-3 {
        width: 33.33333%;
        /* 4/12 */
    }

    .col-5,
    .col-xs-5,
    .col-sm-4,
    .col-md-4,
    .col-lg-4 {
        width: 41.66667%;
        /* 5/12 */
    }

    .col-6,
    .col-xs-6,
    .col-sm-5,
    .col-md-5,
    .col-lg-5 {
        width: 50%;
        /* 6/12 */
    }

    .col-7,
    .col-xs-7,
    .col-sm-6,
    .col-md-6,
    .col-lg-6 {
        width: 58.33333%;
        /* 7/12 */
    }

    .col-8,
    .col-xs-8,
    .col-sm-7,
    .col-md-7,
    .col-lg-7 {
        width: 66.66667%;
        /* 8/12 */
    }

    .col-9,
    .col-xs-9,
    .col-sm-8,
    .col-md-8,
    .col-lg-8 {
        width: 75%;
        /* 9/12 */
    }

    .col-10,
    .col-xs-10,
    .col-sm-9,
    .col-md-9,
    .col-lg-9 {
        width: 83.33333%;
        /* 10/12 */
    }

    .col-11,
    .col-xs-11,
    .col-sm-10,
    .col-md-10,
    .col-lg-10 {
        width: 91.66667%;
        /* 11/12 */
    }

    .col-12,
    .col-xs-12,
    .col-sm-11,
    .col-md-11,
    .col-lg-11 {
        width: 100%;
        /* 12/12 */
    }
}

/* Extra large devices (extra large desktops) */
@media (min-width: 1200px) {

    .col-2,
    .col-xs-2,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xl-1 {
        width: 16.66667%;
        /* 2/12 */
    }

    .col-3,
    .col-xs-3,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xl-2 {
        width: 25%;
        /* 3/12 */
    }

    .col-4,
    .col-xs-4,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xl-3 {
        width: 33.33333%;
        /* 4/12 */
    }

    .col-5,
    .col-xs-5,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xl-4 {
        width: 41.66667%;
        /* 5/12 */
    }

    .col-6,
    .col-xs-6,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xl-5 {
        width: 50%;
        /* 6/12 */
    }

    .col-7,
    .col-xs-7,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xl-6 {
        width: 58.33333%;
        /* 7/12 */
    }

    .col-8,
    .col-xs-8,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xl-7 {
        width: 66.66667%;
        /* 8/12 */
    }

    .col-9,
    .col-xs-9,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xl-8 {
        width: 75%;
        /* 9/12 */
    }

    .col-10,
    .col-xs-10,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xl-9 {
        width: 83.33333%;
        /* 10/12 */
    }

    .col-11,
    .col-xs-11,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xl-10 {
        width: 91.66667%;
        /* 11/12 */
    }

    .col-12,
    .col-xs-12,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xl-11 {
        width: 100%;
        /* 12/12 */
    }
}